import Image from 'next/image';
import RegisterCta from '../utility/registerCta'
import Cta from './cta'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

function Column({
  numberIcon,
  numberAlt,
  featureImage,
  featureAlt,
  text
}) {
  return (
    <div className='flex flex-col w-1/3 mx-1 sm:mx-4'>
      <div className="flex justify-center items-center">
        <div className="relative h-14 w-14 sm:h-24 sm:w-24">
          <Image
            className='block'
            src={numberIcon}
            alt={numberAlt}
            layout="fill"
            objectFit="contain"
          />
        </div>
      </div>
      <div className="max-w-3xs mx-auto sm:-mt-5">
        <Image
          className='block'
          src={featureImage}
          alt={featureAlt}
          width={300}
          height={300}
        />
      </div>
      <p className='text-center text-grey font-bold text-sm sm:text-base md:text-2xl sm:px-4 md:px-6'>{text}</p>
    </div>
  )
}

function HowBlinkWorks({
  heading,
  description,
  steps,
  ctaButtonText
}) {
  return (
    <>
      <div className="absolute border-t-4 border-dotted border-green -mt-0.5 w-full h-2"/>
      <div className='absolute -mt-2 md:-mt-9 w-full'>
        <div className="flex justify-center">
          <div className="bg-green hand-drawn-border-radii text-white font-bold text-xl md:text-3xl p-3 md:mt-4">
            <div className="-mt-1 md:p-3">
              {heading}
            </div>
          </div>
        </div>
      </div>
      <div className="text-sm text-gray-500 text-center font-semibold md:text-xl mt-4 md:mt-6 pt-12 mx-6 sm:mx-auto sm:max-w-xl md:max-w-2xl">
        {documentToReactComponents(description)}
      </div>
      <div className='sm:max-w-3xl md:max-w-5xl mx-auto'>
        <div className='flex mx-4 my-4 md:my-8 relative'>
          <div className='absolute top-0 h-8 sm:h-12 mx-auto left-0 right-0 border-b-4 w-2/3 border-dotted border-grey'></div>
          <Column
            numberIcon={'/static/Number_1_circle.svg'}
            numberAlt={'Step one'}
            featureImage={'/static/How_Blink_Works_step_one_cropped.svg'}
            featureAlt={'Box with blink van icon'}
            text={steps[0]}
          />
          <Column
            numberIcon={'/static/Number_2_circle.svg'}
            numberAlt={'Step two'}
            featureImage={'/static/How_Blink_Works_step_two_cropped.svg'}
            featureAlt={'Personalise icon'}
            text={steps[1]}
          />
          <Column
            numberIcon={'/static/Number_3_circle.svg'}
            numberAlt={'Step three'}
            featureImage={'/static/How_Blink_Works_step_three_cropped.svg'}
            featureAlt={'Get Blink icon'}
            text={steps[2]}
          />
        </div>
        <div className='pb-8 xs:max-w-sm mx-auto'>
          <RegisterCta>
            <div
              className='p-6'
              style={{background: 'radial-gradient(closest-side, rgba(218,145,137,0.6) 0%, rgba(218,145,137,0.6) 30%, rgba(255,255,255,1) 100%)'}}
            >
              <Cta
                ctaButtonText={ctaButtonText}
                id="try-now-how"
                large
              />
            </div>
          </RegisterCta>
        </div>
      </div>
    </>
  )
}

export default HowBlinkWorks;
