import { identifyUser, trackEvent } from "./segment";

function boxSizeSelected(boxSize, additionalProperties = {}) {
  // identifyUser(null, {
  //   'Box size': boxSize
  // })

  trackEvent('Box size selected', {
    ...additionalProperties,
    'Box size': boxSize
  })
}

function cardErrorReceived(error, additionalProperties = {}) {
  trackEvent('Card error received', {
    ...additionalProperties,
    'Error code': error.code,
    'Error type': error.type,
    'Error message': error.message
  })
}

function catAdded(additionalProperties = {}) {
  trackEvent('Cat added', {
    ...additionalProperties,
  });
}

function catInformationSubmitted(cats, additionalProperties = {}) {
  // identifyUser(null, {
  //   'Number of Cats': cats.length,
  //   'Cat names': cats.map((cat) => cat.name),
  //   'Cat breeds': cats.map((cat) => cat.breed),
  //   'Cat birth years': cats.map((cat) => cat.birthYear),
  // })

  trackEvent('Cat information submitted', {
    ...additionalProperties,
    'Number of Cats': cats.length,
    'Cat names': cats.map((cat) => cat.name),
    'Cat breeds': cats.map((cat) => cat.breed),
    'Cat birth years': cats.map((cat) => cat.birthYear),
  })
}

function catRemoved(additionalProperties = {}) {
  trackEvent('Cat removed', {
    ...additionalProperties,
  });
}

function promotionApplied(promotion, additionalProperties = {}) {
  // identifyUser(null, {
  //   'Number of promotions applied': 1,
  // })
  trackEvent('Promotion applied', {
    ...additionalProperties,
    'Code': promotion.code,
    'Value in pounds': (promotion.amount_in_pence / 100.0),
    'Number of boxes promotion valid': promotion.max_application_count,
    'Name': promotion.name,
    'Number of promotions applied': 1,
  });
}

function tryNowClicked() {
  trackEvent('Try now clicked');
}

export default {
  boxSizeSelected,
  cardErrorReceived,
  catAdded,
  catInformationSubmitted,
  catRemoved,
  promotionApplied,
  tryNowClicked,
}

