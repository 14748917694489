import Link from 'next/link';
import Image from 'next/image';

const Pouch = () => (
  <div className="flex items-center h-48 w-48 sm:h-64 sm:w-64 -mb-24 sm:-mb-20">
    <Image
      src='/static/pouch-with-jumpy.png'
      width={3000}
      height={3000}
      alt='Blink food pouch'
    />
  </div>
)

const Van = () => (
  <div className='w-van-mobile sm:w-van-desktop sm:-mb-1 lg:-mr-8'>
    <Image
      src='/static/blink-van.png'
      alt='Sketch of a Blink van'
      width={5000}
      height={1620}
    />
  </div>
)

function FooterLink({path, copy}) {
  return (
    <>
      <div className='p-1'>
        <Link href={path}>
          <a>{copy}</a>
        </Link>
      </div>
      <div className='text-sm'> |</div>
    </>
  )
}

function Footer({links}) {
  return (
    <>
      <div className="flex justify-center -mb-2 items-end sm:mx-5">
        <Pouch />
        <Van />
      </div>
      <div className='bg-grey background-border-top-grey'>
        <div className='pt-10 md:pt-12 flex justify-center'>
          <Link href={'/'}>
            <a>
              <Image
                className='object-scale-down'
                src='/static/logo.png'
                alt='Blink logo'
                width={110}
                height={41}
              />
            </a>
          </Link>
        </div>
        <div className='grid justify-items-center mx-2 pb-3'>
          <div className='h-8 mt-2 mb-6'>
            <span className='px-2'>
              <Link href={'https://www.facebook.com/blinkcatfood/'}>
                <a>
                  <Image
                    className='object-contain h-full h-8 my-3'
                    src='/static/facebook.svg'
                    alt='Facebook icon'
                    width={40}
                    height={40}
                  />
                </a>
              </Link>
            </span>
            <span className='px-2'>
              <Link href={'https://www.instagram.com/blinkcatfood/'}>
                <a>
                  <Image
                    className='object-contain h-full px-2'
                    src='/static/instagram.svg'
                    alt='Instagram icon'
                    width={40}
                    height={40}
                  />
                </a>
              </Link>
            </span>
            <span className='px-2'>
              <Link href={'mailto:help@blinkcats.co.uk'}>
                <a>
                  <Image
                    className='object-contain h-full px-2'
                    src='/static/email.svg'
                    alt='Email icon'
                    width={40}
                    height={40}
                  />
                </a>
              </Link>
            </span>
          </div>
          <div className='flex flex-wrap justify-center mx-auto text-white text-xs'>
              {links.map((linkItem) => {
                return (
                  <FooterLink
                    path={linkItem.fields.linkUrl}
                    copy={linkItem.fields.linkCopy}
                    key={linkItem.fields.linkUrl}
                  />
                )
              })}
            <div className='p-1'>&copy; { new Date().getFullYear() } Blink</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer;
