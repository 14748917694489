import React from 'react';
import Image from 'next/image'

import HandDrawnButton from './handDrawnButton';

const ProductButton = ({ctaButtonText}) => (
  <HandDrawnButton
    path="/products"
    text={ctaButtonText}
    id="see-all-products"
    extraPadding
  />
);

// Adds a red "glow" to its children
const Glow = ({children}) => (
  <div className="px-16 py-10 -mt-4">
    {children}
    {/* This has been commented out at Damian's request. He would like to see
    the button without the Glow. At some point we should either uncomment
    this or delete it.
    <style jsx>{`
      div {
        background: radial-gradient(closest-side, rgb(218, 145, 137), rgb(255, 255, 255))
      }
    `}</style> */}
  </div>
);

function ViewOurProducts({ctaButtonText}) {
  return (
    <div className="md:bg-green background-border-top-green-lg">
      <div className="border-t-4 border-dotted border-green" />
      <div className="mx-6 mt-4 md:mt-12 -mb-3 max-w-xl sm:max-w-3xl sm:mx-auto">
        <Image
          src="/static/entire-product-range.png"
          alt="Seven Blink pouches"
          width={5000}
          height={1202}
          layout='responsive'
        />
      </div>
      <div className="flex justify-center">
        <div className="md:hidden">
          <Glow>
            <ProductButton ctaButtonText={ctaButtonText} />
          </Glow>
        </div>
        <div className="hidden md:block mt-4 mb-16">
          <ProductButton ctaButtonText={ctaButtonText} />
        </div>
      </div>
    </div>
  )
};

export default ViewOurProducts;
