import React from 'react';
import Head from 'next/head';
import tailwindcss from '../src/tailwindcss';

import getContentfulContent from '../src/getContentfulContent';
import UTMManager from '../src/utmManager';
import Navbar from '../components/layout/navbar';
import Hero from '../components/landing/hero';
import WhyBlink from '../components/landing/whyBlink';
import ViewOurProducts from '../components/landing/viewOurProducts';
import Benefits from '../components/landing/benefits';
import HowBlinkWorks from '../components/landing/howBlinkWorks';
import TrustPilotReviews from '../components/landing/trustPilotReviews';
import SupermarketLogos from '../components/landing/supermarketLogos';
import Footer from '../components/landing/footer';

import Flash from '../components/flash/tailwind';

// Landing page
class Index extends React.Component {

  static async getInitialProps({ query }) {
    const content = getContentfulContent('landingPage-05-2021');
    const utmObject = UTMManager.extractParamsFrom(query);

    return {
      ...(await content),
      loggedOut: query.loggedOut === 'true',
      passwordChanged: query.passwordChanged,
      utmObject,
    }
  }

  componentDidMount() {
    UTMManager.store(this.props.utmObject);
    // Clear sessionStorage if the user has logged out.
    if (this.props.loggedOut) {
      sessionStorage.clear();
    }
  }

  render() {
    const { passwordChanged } = this.props;
    return (
      <>
        <Head>
          <title>{this.props.title}</title>
          {/* Preload font */}
          <link
            rel="preload"
            href="/fonts/tile-font-bold.ttf"
            as="font"
            crossOrigin=""
          />
        </Head>

        <Navbar />

        { passwordChanged && <Flash>Your password was successfully changed.</Flash> }

        <Hero
          heading={this.props.heroHeading}
          description={this.props.heroDescription}
          mobileImage={this.props.heroMobileImage}
          desktopImage={this.props.heroDesktopImage}
          ctaButtonText={this.props.heroCtaText}
        />

        {/*
          The order of sections changes based on the size of the viewport, so
          I'm hiding sections and displaying others on different screen sizes.
        */}
        <div className="md:hidden mt-8">
          <WhyBlink
            heading={this.props.whyBlinkHeading}
            image={this.props.whyBlinkImage}
            textDocument={this.props.whyBlinkText}
          />
        </div>
        <div className="hidden md:flex justify-center max-w-7xl mx-auto">
          <div className="grid grid-cols-2">
            <WhyBlink
              heading={this.props.whyBlinkHeading}
              image={this.props.whyBlinkImage}
              textDocument={this.props.whyBlinkText}
            />
            <div className="hidden md:block -z-1">
              <Benefits heading={this.props.benefitsHeading} image={this.props.benefitsImage} />
            </div>
          </div>
        </div>

        <ViewOurProducts
          ctaButtonText={this.props.ourProductsButtonText}
        />

        <div className="md:hidden">
          <Benefits heading={this.props.benefitsHeading} image={this.props.benefitsImage} />
        </div>

        <HowBlinkWorks
          heading={this.props.howBlinkWorksHeading}
          description={this.props.howBlinkWorksDescription}
          steps={this.props.howBlinkWorksSteps}
          ctaButtonText={this.props.heroCtaText}
        />

        <TrustPilotReviews reviews={this.props.trustPilotReviews} />

        <SupermarketLogos
          images={this.props.supermarketLogos}
          text={this.props.supermarketText}
        />

        <Footer links={this.props.footerLinks} />

        {/* Once we are migrated to Tailwind everywhere we can stop inlining
        it with styled-jsx. But for now we need this because otherwise
        Tailwind's global styles mess with our Material UI pages. */}
        {'test' !== process.env.NODE_ENV && <style global jsx>{tailwindcss}</style> }
      </>
    )
  }
}

export default Index;
