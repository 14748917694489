import Link from 'next/link';
import Image from 'next/image';

import Cta from '../landing/cta';
import LoggedOut from '../loggedOut';
import LoggedIn from '../loggedIn';
import { useState } from 'react';
import SegmentEvents from '../../src/segmentEvents';

function handleTryNowClick() {
  SegmentEvents.tryNowClicked();
}

function Navbar() {
  const [open, setOpen] = useState(false);

  return (
    <div className='sticky top-0 bg-green background-border-bottom-green z-50'>
      <div className="h-20">
        <div className="grid grid-cols-3 items-center p-4 max-w-xl m-auto">
          <div className="mr-auto">
            <Cta
              ctaButtonText="Try now"
              id="try-now-header"
              handleClick={handleTryNowClick}
            />
          </div>
          <div className="ml-2 text-center">
            <Link href={'/'}>
              <a>
                <Image
                  src="/static/logo-black.png"
                  alt="Blink logo"
                  width={110}
                  height={41}
                />
              </a>
            </Link>
          </div>
          <div className="ml-auto">
            <div className='flex flex-row'>
              <LoggedOut>
                <Link href={NEW_APP_DOMAIN ? `${NEW_APP_DOMAIN}/login` : '/login'}>
                  <a>
                    <Image
                      src="/static/login.svg"
                      alt="Login"
                      width={50}
                      height={41}
                    />
                  </a>
                </Link>
              </LoggedOut>

              <LoggedIn>
                <Link href={'/logout'}>
                  <a>
                    <Image
                      src="/static/logout.svg"
                      alt="Logout"
                      width={50}
                      height={41}
                    />
                  </a>
                </Link>
              </LoggedIn>

              <ChangeNavIcon
                open={open}
                onClick={() => setOpen(!open)}
              />
            </div>
          </div>
        </div>
      </div>
      { open && <NavDropdown /> }
    </div>
  )
}

function ChangeNavIcon(props) {
  return (
    <>
      <a
        className='flex flex-col justify-center pb-3 cursor-pointer'
        onClick={props.onClick}
        >
        {props.open ? (
          <Image
            src='/static/Mobile_menu_close_X.svg'
            alt='Close menu'
            width={25}
            height={25}
          />
        ) : (
          <Image
            src='/static/burger-menu.svg'
            alt='Open menu'
            width={25}
            height={25}
          />
        )}
      </a>
    </>
  )
}

function NavDropdown() {
  return (
    <div className='flex flex-col lg:flex-row lg:justify-center text-white ml-6 pb-4'>
      <LoggedIn withSubscription>
        <MenuItem text={'My Account'} path={'/personalInformation'} />
        <MenuItem text={'My Orders'} path={'/nextOrder'} />
        <MenuItem text={'Change Subscription'} path={'/amendSubscription'} />
      </LoggedIn>

      <LoggedIn withCancelledSubscription>
        <MenuItem text={'My Account'} path={'/personalInformation'} />
        <MenuItem text={'My Orders'} path={'/nextOrder'} />
        <MenuItem text={'Restart Subscription'} path={'/changeShippingDate?restartingSubscription=true'} />
      </LoggedIn>
      <MenuItem text={'Our Products'} path={'/products'} />
      <MenuItem text={'FAQs'} path={'/faqs'} />
      <div className='lg:hidden'>
        <MenuItem text={'Terms & Conditions and Privacy Policy'} path={'/static/terms.pdf'} />
      </div>
      <MenuItem text={'Contact Us'} path={'mailto: help@blinkcats.co.uk'} />
    </div>
  )
}

function MenuItem({text, path}) {
  return (
    <div className='my-2 lg:px-4'>
      <Link href={path}>
        <a>{text}</a>
      </Link>
    </div>
  )
}

export default Navbar
