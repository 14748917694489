import Cta from '../cta'
import LoggedIn from '../../loggedIn'
import HandDrawnButton from '../handDrawnButton'
import HeroHeading from './heroHeading'

export default function HeroDesktop({
  heading,
  description,
  desktopUrl,
  ctaButtonText
}) {
  return (
    <div className="lg:flex justify-center">
      <div
        className="root bg-cover bg-no-repeat bg-center max-w-8xl"
      >
        <div className="flex md:justify-center">
          <div className="max-w-2xs lg:max-w-sm xl:max-w-md ml-5 mr-48 my-16 lg:my-20">
            <HeroHeading heading={heading} />
            <div className='text-grey font-bold pt-5 mb-6 lg:mb-48'>
              {description}
            </div>
            <div className="sm:max-w-sm">
              <LoggedIn withSubscription>
                <HandDrawnButton
                  path={'/nextOrder'}
                  text={'Go to my account'}
                  large
                />
              </LoggedIn>
              <Cta
                ctaButtonText={ctaButtonText}
                id="try-now-hero"
                large
              />
            </div>
          </div>
          {/* Offset the central column on wide screens */}
          <span className="w-128 hidden md:block"/>
        </div>

        <style jsx>{`
          .root {
            background-image: url('${desktopUrl}');
          }
          @media (min-width: 1024px) {
            .root {
              background-image: linear-gradient(90deg, rgba(203,223,212,1) 0%, rgba(203,223,212,0) 4%, rgba(203,223,212,0) 96%, rgba(203,223,212,1) 100%), url('${desktopUrl}')
            }
          }
        `}
        </style>
      </div>
    </div>
  )
}
