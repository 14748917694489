import { useState } from 'react';
import Image from 'next/image';
import Carousel from 'nuka-carousel';

// Function to render the html used for one slide in the TrustPilotReviews.
// Takes a Trust Pilot Review object from Contentful and returns an image and
// a text div.
function slidesForCarousel({fields: {title, review, image}}, i) {
  const imageDescription = image.fields.description
  const url = image.fields.file.url

  return (
    <div key={i}>
      <div className="mx-2 mb-2">
        <div className="aspect-w-4 aspect-h-3">
          <picture>
            <source
              srcSet={url + "?fm=webp 1x"}
              type="image/webp"
              alt={imageDescription}
              />
            <img
              className="h-full w-full object-cover"
              src={url}
              alt={imageDescription}
              />
          </picture>
        </div>
      </div>
      <div className="bg-white p-4 mx-2">
        <div className="font-bold mb-2">{title}</div>
        <div className="h-64 sm:h-40 md:h-60 lg:h-44 xl:h-32">{review}</div>
      </div>
    </div>
  )
};

// Function to return a Nuka Carousel. Takes an array of Trust Pilot Review
// objects from Contentful, the number of slides to show at once, a callback to
// modify the current slide index and the current slide index.
function TrustPilotCarousel({ reviews, slidesToShow, afterSlide, slideIndex }) {
  return (
    <Carousel
      slidesToShow={slidesToShow}
      renderCenterLeftControls={({ previousSlide }) => (
        <button
          onClick={previousSlide}
          className="-ml-5 mb-40 sm:mb-60 md:mb-80 lg:mb-60 xl:mb-40 focus:outline-none"
          aria-label="Previous Trustpilot review"
        >
          <Image
            src="/static/chervron-arrow-left.svg"
            alt="Previous arrow"
            width={60}
            height={60}
          />
        </button>
      )}
      renderCenterRightControls={({ nextSlide }) => (
        <button
          onClick={nextSlide}
          className="-mr-5 mb-40 sm:mb-60 md:mb-80 lg:mb-60 xl:mb-40 focus:outline-none"
          aria-label="Next Trustpilot review"
        >
          <Image
            src="/static/chervron-arrow-right.svg"
            alt="Next arrow"
            width={60}
            height={60}
          />
        </button>
      )}
      renderBottomCenterControls={null}
      slideIndex={slideIndex}
      afterSlide={afterSlide}
    >
      {reviews.map(slidesForCarousel)}
    </Carousel>
  )
};

// Function to build the Trust Pilot Reviews section.
// Takes an array of Trust Pilot Review objects from Contentful.
function TrustPilotReviews({ reviews }) {
  const [slideIndex, setSlideIndex] = useState(0);

  const carouselWithWidth = number => {
    return (
      <TrustPilotCarousel
        reviews={reviews}
        slidesToShow={number}
        afterSlide={slideIndex => setSlideIndex(slideIndex)}
        slideIndex={slideIndex}
      />
    )
  };

  return (
    <>
      <div className="bg-green mt-10 md:mt-8">
        <div className="flex justify-center">
          <div className="text-white bg-green hand-drawn-border-radii text-2xl font-bold p-6 -mt-10 rounded-3xl">
            <div className="-mt-1">
              Our Blink Fans
            </div>
          </div>
        </div>
        <div className="mb-5 p-8 max-w-7xl mx-auto">
          <div className="sm:hidden">
            {carouselWithWidth(1)}
          </div>
          <div className="hidden sm:block md:hidden">
            {carouselWithWidth(2)}
          </div>
          <div className="hidden md:block">
            {carouselWithWidth(3)}
          </div>
        </div>
      </div>
    </>
  )
};

export default TrustPilotReviews;
