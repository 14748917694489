// Public: Only renders its children if there's no user
// TODO: Refactor this and loggedIn ?
import CurrentCustomerContext from '../src/currentCustomerContext';

const LoggedOut = ({children}) => (
  <CurrentCustomerContext.Consumer>{
    currentCustomer => (currentCustomer ? null : children)
  }</CurrentCustomerContext.Consumer>
);

export default LoggedOut;
