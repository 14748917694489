// Public: Only renders its children if there's a logged in user
import CurrentCustomerContext from '../src/currentCustomerContext';
import React from 'react';
import PropTypes from 'prop-types';

const LoggedIn = ({ children, withSubscription, withoutSubscription, withCancelledSubscription }) => (
  <CurrentCustomerContext.Consumer>{
    currentCustomer => {
      if (currentCustomer) {
        if (!withoutSubscription && !withSubscription && !withCancelledSubscription) {
          return children;
        }
        if (withSubscription && currentCustomer.subscription_id && currentCustomer.status === 'Subscribed') {
          return children;
        }
        if (withoutSubscription && !currentCustomer.subscription_id && currentCustomer.status !== 'Subscribed') {
          return children;
        }
        if (withCancelledSubscription && currentCustomer.status === 'Cancelled') {
          return children;
        }
      }
      return null;
    }
  }</CurrentCustomerContext.Consumer>
);

// Props are mutually exclusive and should not be combined.
LoggedIn.propTypes = {
  withSubscription: PropTypes.bool, // renders children only if user has active subscription
  withoutSubscription: PropTypes.bool, // renders children only if user has no subscription
  withCancelledSubscription: PropTypes.bool // renders children only if user has a cancelled subscription
}

LoggedIn.defaultProps = {
  withSubscription: false,
  withoutSubscription: false,
  withCancelledSubscription: false
}

export default LoggedIn;
