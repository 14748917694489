import Image from 'next/image';
import ContentfulLoader from '../../src/contentfulLoader';

function Benefits({heading, image}) {
  return (
    <div className="relative">
      <div className="absolute z-10 mt-5 w-full">
        <div className="p-0.5 sm:p-2 w-1/3 sm:w-1/2 md:w-1/3 lg:w-1/2 xl:w-1/3 ml-auto mr-4 bg-green hand-drawn-border-radii">
          <div className="text-white m-2 font-bold text-right text-xs sm:text-lg md:text-sm lg:text-lg xl:text-xl">
            {heading}
          </div>
        </div>
      </div>
      <div className="relative w-full aspect-w-1 aspect-h-1">
        <Image
          alt="Cat eating blink"
          src={`https:${image.fields.file.url}`}
          layout="fill"
          objectFit="cover"
          loader={ContentfulLoader}
        />
      </div>
    </div>
  )
}

export default Benefits
