import Image from 'next/image'
import ContentfulLoader from '../../src/contentfulLoader'

// Function to render a supermarket logo.
// Takes a contentful image object and returns html for a logo image.
function Logo(imageObject) {
  return (
    <div className="flex-50 md:flex-25" key={imageObject.fields.file.url}>
      <div className="h-20 md:h-28 p-2">
        <div className='flex h-full w-full justify-center relative'>
          <Image
            src={`https:${imageObject.fields.file.url}`}
            layout='fill'
            objectFit='contain'
            loader={ContentfulLoader}
          />
        </div>
      </div>
    </div>
  )
}

// Function to render the supermarket logo section.
// Takes an array of image objects and a text string.
// Returns html for the supermarket logo section.
function SupermarketLogos({images, text}) {
  return (
    <div className="sm:mb-5">
      <p className="my-4 md:my-9 text-gray-600 text-center text-sm md:text-lg">{text}</p>
      <div className="max-w-lg md:max-w-5xl mx-auto">
        <div className="flex flex-wrap justify-center mx-20">
          { images.map(Logo) }
        </div>
      </div>
    </div>
  )
}

export default SupermarketLogos;
