const contentful = require('contentful');

/*
  This is an example of the usage of getContentfulContent():

  static async getInitialProps() {
    let content = getContentfulContent('faQs');
   
    return {
      ...(await content)
    }
  }

*/

// Internal: Get content from Contentful. Call this from getInitialProps().
export default async function getContentfulContent(contentType) {
  
  const client = contentful.createClient({
    space: CONTENTFUL_SPACE,
    accessToken: CONTENTFUL_TOKEN,
    environment: CONTENTFUL_ENVIRONMENT || 'master'
  });
  
  // Fetch content specified using content_type
  const entries = await client.getEntries({
    content_type: contentType,
    order: '-sys.createdAt',
    limit: 1
  });
  
  if (entries.total > 0) {
    const fields = entries.items[0].fields;
    return fields;
  }
  
  // Danger danger! There's not really an appropriate action to take here.
  return {};
}
