import css from 'styled-jsx/css';

export default css.global`
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Blink Tile";
  src: url("/fonts/tile-font-bold.ttf");
  font-style: medium;
  font-weight: 500;
  font-display: swap;
}

.hand-drawn-border-radii{
  border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
  border-width: 2px 4px;
  border-color: white;
}
`;
