import Image from 'next/image';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

function WhyBlink({
  heading,
  image: {
    fields: {
      file: { url } = {},
      description
    } = {}
  },
  textDocument
}) {
  return (
    <div className="mt-5 md:mt-0 pb-3 md:max-w-1/2 background-border-right-white">
      <div className="flex justify-between items-start">
        <div
          className="text-white font-bold text-xl ml-5 p-3 md:mt-4 z-10 bg-green"
        >
          { heading }
        </div>
        <div className="mr-7">
          <Image
            src="/static/made-in-britain.svg"
            alt="Made in Britain"
            width={110}
            height={100}
          />
        </div>
      </div>
      <div className="flex justify-center">
        <div className="ml-3 mr-1 -mt-10 mb-3 md:-mt-7 md:mx-11 lg:mx-8">
          <picture className="bg-green-light">
            <source
              srcSet={`${url}?fm=webp&q=75 1x`}
              type="image/webp"
              alt={description}
              />
            <img
              src={`${url}?q=75`}
              alt={description}
              width={3000}
              height={2597}
              />
          </picture>
        </div>
      </div>
      <div className="flex justify-center">
        <div className="text-sm text-gray-500 mx-auto text-center">
          { documentToReactComponents(textDocument) }
        </div>
      </div>
    </div>
  )
}

export default WhyBlink;
