import { getCookieConsentValue } from "react-cookie-consent";

// Check if user has given consent for tracking
function hasTrackingConsent() {
  return getCookieConsentValue() === "true";
}

// Check if third-party script is ready
function isConfigured() {
  if (!window.analytics) {
    console.error("Segment Analytics.js script has not been initialised");
    return false;
  }
  return true;
}

// Check if we should be using Segment Analytics.js
function isEnabled() {
  if (process.env.SEGMENT_ANALYTICS_DISABLED !== "true") {
    return isConfigured() && hasTrackingConsent();
  }
  return false;
}

// Wrapper function for analytics.identify
export function identifyUser(userId, traits = {}) {
  if (isEnabled()) {
    if (userId) {
      return window.analytics.identify(userId, traits);
    } else {
      if (Rollbar) {
        Rollbar.error("userId is null and we tried to identifyUser");
      }
      // return window.analytics.identify(traits);
    }
  }

  return Promise.resolve(null)
}

// Wrapper function for analytics.track
export function trackEvent(event, properties = {}) {
  if (isEnabled()) {
    return window.analytics.track(event, properties);
  }

  return Promise.resolve(null)
}

export default { identifyUser, trackEvent };
