import LoggedOut from '../loggedOut';
import LoggedIn from '../loggedIn';

// The CTA should only ever be visible to users that are either logged out, or
// logged in but haven't finished signing up, and only if the SHOW_REGISTER_CTA
// toggle is on.
const RegisterCta = ({children}) => (
  SHOW_REGISTER_CTA ? 
    <>
      <LoggedOut>
        {children}
      </LoggedOut>

      <LoggedIn withoutSubscription>
        {children}
      </LoggedIn>
    </>
  : null
);

export default RegisterCta;
