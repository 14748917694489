export default function ContentfulLoader({src, width, quality}) {
  // Parse URI so we can add some parameters to it (user may have forced
  // the image to a format e.g. with ?fm=jpg).
  const uri = new URL(src);
  const searchParams = new URLSearchParams(uri.search);
  // Add width & quality
  searchParams.set('w', width);
  searchParams.set('q', quality || 75);
  uri.search = searchParams.toString();
  return uri.toString();
}
