import React from 'react';
import PropTypes from 'prop-types';

import RegisterCta from '../utility/registerCta';
import HandDrawnButton from './handDrawnButton';
import FeatureFlagsContext from '../../src/featureFlagsContext';
import DestinationLookup from '../../src/destinationLookup';

class Cta extends React.Component {
  static contextType = FeatureFlagsContext

  render() {
    const { ctaButtonText, large, id, handleClick } = this.props;
    let ctaUrl = '/catRegistration';

    // If the twin frontends are enabled, use the new site URL.
    const featureFlags = this.context;
    if (featureFlags && featureFlags.twin_frontends_enabled) {
      // ROLLBAR_ENV can be 'production', 'development', or 'staging'.
      ctaUrl = DestinationLookup[ROLLBAR_ENV];
    }

    return (
      <RegisterCta>
        <HandDrawnButton
          path={ ctaUrl }
          text={ ctaButtonText }
          large={ large }
          id={ id }
          handleClick={ handleClick }
        />
      </RegisterCta>
    );
  }
}

Cta.propTypes = {
  ctaButtonText: PropTypes.string,
  large: PropTypes.bool,
  id: PropTypes.string,
  handleClick: PropTypes.func,
};

export default Cta;
