export default function HeroHeading({ heading }) {

  // Function that takes a string, which is split into words. Each word is
  // rendered individually in its own <div> with the white tile font
  // as a background.
  const wordsFrom = text => {
    return text.split(' ').map((word, index) => {
      return (
        <div className="relative text-2xl lg:text-3xl font-bold" key={index}>
          {/* Spacing in the DOM */}
          <div className="invisible">{word}&nbsp;</div>
          {/* White background font */}
          <div className="absolute top-0 left-0 text-white font-tile">{word}&nbsp;</div>
          {/* Red text font */}
          <div className="absolute top-0 left-0 text-red">{word}&nbsp;</div>
        </div>
      )
    })
  }

  return (
    <div className="flex flex-wrap">
      { wordsFrom(heading) }
    </div>
    )
}