import HeroMobile from './hero/mobile';
import HeroDesktop from './hero/desktop';

// Hero area at the top of the page. Has two separate versions for mobile
// and desktop because the structure is v. different.
function Hero({
  heading,
  description,
  mobileImage: {
    fields: {
      description: imageDescription,
      file: {
        url: mobileUrl
      } = {}
    } = {}
  },
  desktopImage: {
    fields: {
      file: {
        url: desktopUrl
      } = {}
    } = {}
  },
  ctaButtonText
}) {
  return (
    <div className="bg-green-light background-border-bottom-light-green">

      <div className="sm:hidden">
        <HeroMobile
          heading={heading}
          description={description}
          mobileDescription={imageDescription}
          mobileUrl={mobileUrl}
          ctaButtonText={ctaButtonText}
        />
      </div>
      <div className="hidden sm:block ">
        <HeroDesktop
          heading={heading}
          description={description}
          desktopUrl={desktopUrl}
          ctaButtonText={ctaButtonText}
          large
        />
      </div>
    </div>
  )
};

export default Hero;
