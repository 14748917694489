import Cta from '../cta';
import Image from 'next/image';
import LoggedIn from '../../loggedIn'
import HandDrawnButton from '../handDrawnButton'
import HeroHeading from './heroHeading'
import ContentfulLoader from '../../../src/contentfulLoader'

// A box placed over the image to apply a gradient to the top and bottom to 
// smoothly fade it to our green.
const Gradient = () => (
  <div className='absolute inset-0'>
    <style jsx>{`
      div {
        background-image: linear-gradient(0deg, rgba(203, 223, 212,1) 0%, rgba(203,223,212,0) 4%, rgba(203,223,212,0) 96%, rgba(203,223,212,1) 100%)
      }
    `}</style>
  </div>
)

export default function HeroMobile({
  heading,
  description,
  mobileUrl,
  mobileDescription,
  ctaButtonText
}) {
  return (
    <>
      <div className="mx-4 pt-7">
        <HeroHeading heading={heading} />
        <div className='text-grey font-bold pt-5'>
          {description}
        </div>
      </div>
      <div className="relative image-container w-full bg-contain bg-no-repeat bg-right pt-100%">
        <Image 
          src={`https:${mobileUrl}`}
          width={1300} 
          height={1335}
          alt={mobileDescription}
          layout='responsive'
          loader={ContentfulLoader}
        />
        <Gradient />
      </div>
      <div className="pb-16">
        <div className="mx-4">
          <LoggedIn withSubscription>
            <HandDrawnButton
              path={'/nextOrder'}
              text={'Go to my account'}
              large
            />
          </LoggedIn>
          <Cta
            ctaButtonText={ctaButtonText}
            id="try-now-hero"
            large
          />
        </div>
      </div>
    </>
  )
}
