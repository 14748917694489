const Flash = ({children}) => (
  <div className='bg-green-light py-4' >
    <div className='mx-auto max-w-6xl px-4'>
      <div className='bg-yellow-200 border border-yellow-600 px-4 py-3 text-sm text-yellow-900'>
        {children}
      </div>
    </div>
  </div>
);

export default Flash;
